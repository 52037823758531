<template>
  <div class="join_details">
    <Nav nums='4' isColor='#fff'/>
    <div class="detailsBox">
        <div class="cont">
            <div class="details_title">{{postDetail.name}}</div>
            <div class="outline">
                <span>职位：{{postDetail.name}}</span>
                <span>薪资：{{postDetail.min_price}}-{{postDetail.max_price}}元</span>
                <span>人数：{{postDetail.num}}人</span>
                <span>地点：{{postDetail.location}}</span>
            </div>
            <div>
                <div class="text">
                  <div v-html="postDetail.obligation">
                    {{postDetail.obligation}}
                  </div>
                </div>
                <div class="text">
                  <div v-html="postDetail.content">
                    {{postDetail.content}}
                  </div>
                </div>
            </div>
            <div class="back">
              <router-link to="/joinus">返回职位列表</router-link>
            </div>
        </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import Nav from './nav.vue'
import Floor from './floor.vue'
import { request } from '../apis/http'
export default {
  components: {
    Nav,
    Floor
  },
  data () {
    return {
      postid:'',
      postDetail:{}
    }
  },
  created() {
    this.postid = this.$route.query.id
  },
  beforeMount() {
    this.post()
    scrollTo(0, 0)
  },
  methods:{
    async post(){
      const res = await request({
        url:`/api/index/recruitinfo?id=${this.postid}`
      })
      this.postDetail = res.data.data
    }
  }
}
</script>
<style lang="less">
@import './fn.less';
html,body,.join_details{
  height: 100%;
}
.detailsBox{
  width: 100%;
  height: 60.67708vw;
  background: url('../assets/join.jpg');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vw;
  .cont{
      width:60.5vw;
      background: rgba(255, 255, 255, 0.4);
      padding: 2.08333vw 2.604166vw;
      box-sizing: border-box;
      position: relative;
      // overflow: auto;
      .details_title{
          color: #101218;
          font-size: 1.5625vw;
          font-weight: 700;
      }
      .outline{
         color:#57585C;
         font-size: 1.04166vw;
         margin-top: 1.404166vw;
         span{
             margin-right: 3.125vw;
         }
      }
      .text{
          margin-top: 2.004166vw;
          color: #57585C;
          font-size: 1.04166vw;
          line-height: 1.48333vw;
      }
      .back{
          margin-top: 2.08333vw;
          a{
              color: #101218;
              font-size: 1.04166vw;
              text-decoration: none;
          }
      }
  }
}
@media screen and (max-width: 980px) {
  .join_details{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .detailsBox{
  width: 100%;
  height: 375vw;
  background: url('../assets/join.jpg');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11vw;
  .cont{
      width:80.5vw;
      height: 100%;
      background: rgba(255, 255, 255, 0.4);
      padding-top: 20.08333vw;
      margin-bottom: 20.08333vw;
      box-sizing: border-box;
      position: relative;
      .details_title{
        color: #101218;
        font-size: 5.5625vw;
        font-weight: 700;
      }
      .outline{
         color:rgb(46, 52, 59);
         font-size: 3.848979vw;
         margin-top: 2.404166vw;
         span{
             margin-right: 3.125vw;
         }
      }
      .text{
          margin-top: 4.004166vw;
          color: #57585C;
         .vvw(font-size,24);
          line-height: 4.48333vw;
          p{
            font-size: 3.848979vw!important;
          }
      }
      .back{
          margin-top: 5.08333vw;
          a{
              color: #101218;
             .vvw(font-size,36);
              text-decoration: none;
          }
      }
  }
}
  }
</style>
